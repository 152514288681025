import { effect, inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { combineLatest, filter, map } from 'rxjs';
import { NavigationService } from './navigation.service';
import {
  IProductCart,
  ShoppingCartService,
} from 'src/app/shopping-cart/services/shoppingCart.service';
import { ProductCartClass } from 'src/app/shopping-cart/interfaces/productCart.class';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private rutas = {
    3: '/productos',
    10: '/canje-juegos',
    11: '/carro-compras',
    15: '/reserva-confirm',
    29: '/cuenta',
    30: '/cuenta/reservas/idReserva/comprobantes',
    31: '/cuenta/reservas/idReserva',
    35: '/cuenta/reservas/idReserva/verificacion-tarjetas',
    42: '/cuenta/gamer-coins',
    44: '/cuenta/preguntas',
    60: '/ayuda',
    70: '/turnero',
    71: '/turnero/71',
    72: '/turnero/72',
    99: '/validar-mail',
  };

  constructor(
    private route: Router,

    private shoppingCartService: ShoppingCartService,
    private navigationService: NavigationService,
  ) {
    this.processInitialUrl();
    this.updateQueryParamsToCartElements();
    this.updateCartsElementToQueryParams();
  }
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  private listadoProdParam = null;
  private modParam = null;
  private cpParam = null;
  private updateQueryParamsToCartElements(): void {
    this.route.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot.queryParams),
      )
      .subscribe(() => {
        const { listado_prod, mod, cp, descuento, codigo_descuento } =
          this.activatedRoute.snapshot.queryParams;
        this.listadoProdParam = listado_prod;
        this.modParam = mod;
        this.cpParam = cp;
        if (this.listadoProdParam) {
          const newCartElementsList = this.listCartelementsString(this.listadoProdParam);
          this.shoppingCartService.addCartElements(newCartElementsList);
        }
        if (this.modParam !== null) {
          this.shoppingCartService.addModality(this.modParam);
        }
        if (this.cpParam !== null) {
          this.shoppingCartService.addPostalCode(this.cpParam);
        }
        if (descuento && codigo_descuento) {
          this.shoppingCartService.setDiscountParams({
            id: +descuento,
            code: codigo_descuento,
          });
        }
      });
  }

  private updateCartsElementToQueryParams(): void {
    effect(() => {
      const stringCartElements = this.stringifyCartElementsParamWithoutBuild();
      this.modParam = this.shoppingCartService.idModality();
      this.cpParam = this.shoppingCartService.postalCode();
      if (stringCartElements || this.modParam || this.cpParam) {
        this.navigationService
          .navigate(
            [],
            {
              queryParams: {
                listado_prod: stringCartElements || null,
                mod: this.modParam ?? null,
                cp: this.cpParam ?? null,
              },
              relativeTo: this.activatedRoute,
              queryParamsHandling: 'merge',
            },
            false,
          )
          .catch((r) => {});
      }
    });
  }

  private processInitialUrl(): void {
    combineLatest([
      this.route.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.routerState.snapshot.root),
      ),
      this.activatedRoute.queryParams,
    ]).subscribe(([_, queryParams]) => {
      const { seccion, liqui, reserva } = queryParams;

      const queryParamsCopy = { ...queryParams };
      let isProductsRouteActive = this.route.isActive('/productos', {
        paths: 'exact',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      });
      if (liqui && !isProductsRouteActive) {
        delete queryParamsCopy['liqui'];
      }

      if (seccion && this.rutas.hasOwnProperty(seccion)) {
        this.navigateWithoutSections(this.rutas[seccion], queryParamsCopy, reserva);
      } else if (liqui && !isProductsRouteActive) {
        this.navigationService.navigateUrlWithoutPersistence([this.route.url.split('?')[0]], {
          queryParams: queryParamsCopy,
          replaceUrl: true,
        });
      }
    });
  }

  private navigateWithoutSections(baseRoute: string, queryParams: Params, reserva?: string): void {
    let newRoute = baseRoute;
    delete queryParams['seccion'];

    if (reserva) {
      newRoute = newRoute.replace('idReserva', reserva);
      delete queryParams['reserva'];
    }

    this.navigationService.navigateUrlWithoutPersistence([newRoute], {
      queryParams,
      replaceUrl: true,
    });
  }

  public stringifyCartElementsParam(): string {
    const cartElements = this.shoppingCartService.getCartElements();
    const productsGroupedByIdBuild = cartElements.reduce(
      (acc, cartElement) => {
        const key = cartElement.idBuild || 'noBuildId';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(cartElement);
        return acc;
      },
      {} as Record<string, ProductCartClass[]>,
    );

    const listStringProcessedCartElements: string[] = Object.entries(productsGroupedByIdBuild).map(
      ([idBuild, cartElements]) => {
        const productsString = cartElements
          .map((product) => `${product.quantity()}-${product.idProduct}`)
          .join(',');

        return idBuild !== 'noBuildId' ? `${productsString},_${idBuild}_` : productsString;
      },
    );

    return listStringProcessedCartElements.join(',');
  }

  public stringifyCartElementsParamWithoutBuild(): string {
    const productsString = this.shoppingCartService
      .getCartElements()
      .map((productInCart) => `${productInCart.quantity()}-${productInCart.idProduct}`)
      .join(',');

    return productsString;
  }

  private listCartelementsString(listadoProdUrlParam: string): IProductCart[] {
    // TODO: descomentar para aplicar la lógica con builds

    // let idBuild: number | null = null;
    // listadoProdUrlParam
    //   ?.split(',')
    //   .reverse()
    //   .forEach((part) => {
    //     if (/^_.*_$/.test(part)) {
    //       idBuild = parseInt(part.slice(1, -1), 10);
    //     } else {
    //       const [quantity, idProduct] = part.split('-').map(Number);
    //       const key = `${idProduct}-${idBuild ?? 'null'}`;

    //       if (cartMap.has(key)) {
    //         cartMap.get(key)!.quantity += quantity;
    //       } else {
    //         cartMap.set(key, {
    //           idProduct,
    //           idBuild: idBuild ?? undefined,
    //           quantity,
    //         });
    //       }
    //     }
    //   });
    const cartMap = listadoProdUrlParam?.split(',').reduce((acc, curr) => {
      const [quantity, idProduct] = curr.split('-').map(Number);

      if (acc.has(idProduct)) {
        acc.set(idProduct, acc.get(idProduct) + quantity);
      } else {
        acc.set(idProduct, quantity);
      }

      return acc;
    }, new Map());

    const cartList = Array.from(cartMap, ([idProduct, quantity]) => ({ idProduct, quantity }));

    return Array.from(cartList);
  }
}
